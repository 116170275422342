define("applicant-cli/controllers/login", ["exports", "applicant-cli/utils/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['isGhost', 'isInvite', 'offerElsewhere', 'orgJobId', 'postingId', 'shouldMigrate'],
    isGhost: null,
    isInvite: false,
    offerElsewhere: false,
    orgJobId: null,
    postingId: null,
    shouldMigrate: false,
    email: null,
    password: null,
    rememberMe: false,
    error: null,
    orgName: null,
    emailValid: false,
    isLoading: false,
    passwordValid: false,
    appController: Ember.inject.controller('application'),
    job: Ember.inject.service(),
    session: Ember.inject.service(),
    user: Ember.inject.service(),
    isValid: Ember.computed.and('emailValid', 'passwordValid'),
    noAccount: Ember.computed.equal('error', 'Account not found.'),
    noPassword: Ember.computed.equal('error', 'Incorrect password.'),
    migratePassword: Ember.computed.equal('error', 'Migrate password.'),
    gtm: Ember.inject.service(),
    actions: {
      login() {
        if (!this.isValid) {
          return;
        }

        this.set('isLoading', true);
        let credential = {
          username: this.email,
          password: this.password,
          rememberMe: this.rememberMe
        };

        _ajax.default.post('login', {
          credential
        }).then(res => {
          if (res.error) {
            this.set('error', res.error);
          } else if (res.forgotPassword) {
            this.transitionToRoute('change-password', res.forgotPassword);
          } else if (res.newAppRoot) {
            let url = "".concat(res.newAppRoot, "/login?offerElsewhere=true");
            window.location = url;
          } else {
            this.session.setToken(res.token, this.rememberMe);
            this.appController.set('internal', res.isInternal);
            this.user.set('applicantId', res.appId);

            if (this.postingId || this.orgJobId) {
              this.job.apply(this.postingId || this.orgJobId);
            } else {
              let {
                transition
              } = this.session;

              if (transition) {
                this.set('session.transition');
                transition.retry();
              } else {
                this.transitionToRoute('index');
              }
            }

            this.gtm.pushEmailToDataLayer(res.encryptedEmail == "" ? "" : res.encryptedEmail);
          }
        }, err => this.send('error', err)).finally(() => this.set('isLoading', false));
      },

      validate(field, valid) {
        this.set('error', null);
        this.set("".concat(field, "Valid"), valid);
      }

    }
  });

  _exports.default = _default;
});